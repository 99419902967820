import React from 'react'
import './Item.css'
import { Link } from 'react-router-dom'

const Item = ({ product }) => {

    return (
        
        <article className="card__box" >
            <img className="card-img-top" src={product.thumbnail} alt={product.title} />
            <div className="item__CardContainer">

                <small className="item__category">{ product.categoryType }  • {product.category}</small>
                <h5 className="item__title">{product.title}</h5>
                <h3 className="item__price">${product.price}</h3>
                <small className="item__stock">Stock: {product.stock} u.</small>
                <div className='item__buttom'>
                    <Link className="btn btn-primary mt-3 w-100" to={`/detail/${product.id}`} >Ver detalles</Link>
                </div>
            </div>
        </article>

    )
}

export default Item