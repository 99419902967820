import React from 'react'

const Carousel = () => {
  return (
    <div >
        <img src="./images/carousel.jpg" className='d-block w-100' alt="" />
    </div>
  )
}

export default Carousel